import React from 'react';
import logo from '../Assets/DC_Logo_Colour.png'; // Replace with the path to your logo image

const Header = () => {
  return (
    <header className="header">
      <a href="https://degencastle.com" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      {/* Add other header elements here if needed */}
    </header>
  );
};

export default Header;