import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { FireContractAddress, EarthContractAddress, WaterContractAddress, MagicContractAddress } from './contract_address';
import EarthABI from '../ABI/Earth_ABI.json';
import FireABI from '../ABI/Fire_ABI.json';
import MagicABI from '../ABI/Magic_ABI.json';
import WaterABI from '../ABI/Water_ABI.json';
import NFTCard from './NFTCard';
import '../CSS/App.css';

import EarthImage from '../Assets/Earth_NFT.gif';
import FireImage from '../Assets/Fire_NFT.gif';
import WaterImage from '../Assets/Water_NFT.gif';
import MagicImage from '../Assets/Magic_NFT.gif';


const PublicMint = () => {
    const { address } = useAccount();
    const { primaryWallet } = useDynamicContext();
    const [nftData, setNftData] = useState({});

    const contracts = [
        { name: 'Earth Amulet', address: EarthContractAddress, abi: EarthABI },
        { name: 'Fire Amulet', address: FireContractAddress, abi: FireABI },
        { name: 'Water Amulet', address: WaterContractAddress, abi: WaterABI },
        { name: 'Magic Amulet', address: MagicContractAddress, abi: MagicABI },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
              throw new Error("No provider")
            }

            const loadData = async (contractInfo) => {
                const contract = new ethers.Contract(contractInfo.address, contractInfo.abi, provider);
                const mintPrice = await contract.mintPrice();
                const totalSupply = await contract.totalSupply();
                const maxSupply = await contract.maxSupply();
                return {
                    ...contractInfo,
                    mintPrice: ethers.utils.formatEther(mintPrice),
                    totalSupply: totalSupply.toString(),
                    maxSupply: maxSupply.toString()
                };
            };

            const allData = await Promise.all(contracts.map(loadData));
            setNftData(allData.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {}));
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePublicMint = async (contractName, contractAddress, contractABI) => {
    if (!address || !primaryWallet) {
      alert("Please connect your wallet");
      return;
    }

    try {
        const signer = await primaryWallet.connector.ethers?.getSigner();

      if (!signer) {
        throw new Error("No signer found");
      }
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        const value = ethers.utils.parseEther("0.25");
        const transaction = await contract.publicMint({ value });

        await transaction.wait();
        alert('Mint successful!');

        // Re-fetch the totalSupply from the contract
        const updatedTotalSupply = await contract.totalSupply();

        // Update the state with the new totalSupply
        setNftData(prevNftData => ({
            ...prevNftData,
            [contractName]: {
                ...prevNftData[contractName],
                totalSupply: updatedTotalSupply.toString(),
            },
        }));
    } catch (error) {
        alert(`Error: ${error.message}`);
    }
};

      const nftDetails = {
        'Earth Amulet': { image: EarthImage, description: `Molded from the Castle's indestructible foundations, this amulet carries the legacy of Degen's resilience. It empowers holders with the fortitude of ancient stone guardians, to stand firm in the most challenging arcade quests.` },
        'Fire Amulet': { image: FireImage, description: `Ignited by the dragon's breath that razed the castle, this amulet burns with the spirit of rebirth and transformation. It inspires gamers with a fiery passion to conquer arcade challenges and rise from the ashes of defeat, ablaze with glory.` },
        'Water Amulet': { image: WaterImage, description: `Forged from the enchanted waters of the castle's moat, this talisman navigates the arcade's shifting tides with ease. Its holder is blessed with water’s cunning, allowing them to flow through games with intuitive strategy and fluid grace.` },
        'Magic Amulet': { image: MagicImage, description: `Conjured in the castle's clandestine dungeons, this amulet thrums with forbidden power. It is the choice of players who seek to master the shadows, bending dark energies to outwit opponents and unlock secret paths.` }
    };

    return (
        <div className="mint-container">
            {contracts.map(({ name, address, abi }) => (
    <NFTCard
        key={name}
        name={name}
        image={nftDetails[name].image}
        description={nftDetails[name].description}
        mintPrice={nftData[name]?.mintPrice}
        totalSupply={nftData[name]?.totalSupply}
        maxSupply={nftData[name]?.maxSupply}
        actionFunction={() => handlePublicMint(name, address, abi)} // Pass the name here
        actionLabel={`Mint ${name}`}
    />
))}

        </div>
    );
};

export default PublicMint;