import React from 'react';

const NFTCard = ({
  name,
  image,
  description,
  mintPrice,
  totalSupply,
  maxSupply,
  actionFunction,
  actionLabel,
  recipientInput // Added this prop for conditional rendering
}) => {
    return (
        <div className="nft-card">
            <img src={image} alt={`${name} NFT`} className="nft-image" />
            <h2>{name}</h2>
            <p>{description}</p>
            {mintPrice && <p>Mint Price: {mintPrice} BNB</p>}
            {totalSupply && maxSupply && <p>{totalSupply} / {maxSupply} minted</p>}
            {recipientInput} {/* This will render the input field if provided */}
            <button onClick={actionFunction}>{actionLabel}</button>
        </div>
    );
};

export default NFTCard;