import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import PublicMint from './PublicMint';
import MintGift from './MintGift';
import JoinWishlist from './JoinWishlist';
import PublicMintWishlist from './PublicMintWishlist';
import MyAmulets from './MyAmulets';

function MintInterface() {
    return (
        <div>
            <div className="nav-wrapper">
                <nav className="navigation">
                    <NavLink to="/myAmulets" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>My Amulets</NavLink>
                    <NavLink to="/publicMint" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Mint Amulets</NavLink>
                    <NavLink to="/mintGift" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Gift Amulets</NavLink>
                    <NavLink to="/joinWishlist" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Join Wishlist</NavLink>
                    <NavLink to="/publicMintWishlist" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Mint A Wish</NavLink>
                </nav>
            </div>
            <div className="main-content">
            <Routes>
                <Route path="/myAmulets" element={<MyAmulets />} />
                <Route path="/publicMint" element={<PublicMint />} />
                <Route path="/mintGift" element={<MintGift />} />
                <Route path="/joinWishlist" element={<JoinWishlist />} />
                <Route path="/publicMintWishlist" element={<PublicMintWishlist />} />
            </Routes>
            </div>
        </div>
    );
}

export default MintInterface;